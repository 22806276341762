import type { SystemStyleObject, SystemStyleFunction } from "@chakra-ui/theme-tools";
import { whiten, blacken } from "@chakra-ui/theme-tools";
import { focusOutline } from "../../../css/02.focus/focus.styles";

const baseStyle: SystemStyleObject = {
    lineHeight: "shorter",
    borderRadius: "none",
    py: 3,
    fontWeight: "semibold",
    fontSize: "desktopBodyTextSmall",
    transitionProperty: "common",
    transitionDuration: "normal",
    _focusVisible: {
        ...focusOutline,
        boxShadow: "none",
    },
    _disabled: {
        opacity: 0.4,
        cursor: "initial",
        boxShadow: "none",
        _hover: {
            color: "inherit",
            background: "gray.100",
        },
    },
    "&[data-loading] > div": {
        w: "84%",
    },
    "&[data-loading]": {
        bg: "black",
        opacity: 1,
        _hover: {
            bg: "black",
        },
    },
};

const variantTertiary: SystemStyleObject = {
    bg: "gray.50",
    color: "black",
    "@media (hover: hover) and (pointer: fine) ": {
        _hover: {
            bg: "gray.100",
            _disabled: {
                bg: "gray.50",
            },
        },
    },
};

const variantButtonFilter: SystemStyleObject = {
    ...baseStyle,
    bg: "transparent",
    color: "black",
    _hover: {
        bg: "transparent",
    },
    _active: {
        bg: "black",
        color: "white",
    },
};

const variantOutline: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;
    const base = {
        bg: "transparent",
        _disabled: {
            color: "black",
        },
        _active: {
            color: "black",
        },
    };

    if (c === "black") {
        return {
            ...base,
            _focusVisible: {
                ...focusOutline,
            },
            "@media (hover: hover) and (pointer: fine) ": {
                _hover: {
                    bg: "gray.100",
                    textDecoration: "none",
                },
            },
        };
    }

    if (c === "white") {
        return {
            ...base,
            color: "black",
            _focusVisible: {
                ...focusOutline,
                boxShadow: "none",
            },
            "@media (hover: hover) and (pointer: fine) ": {
                _hover: {
                    bg: "gray.100",
                    color: "black",
                },
            },
        };
    }

    if (c === "gray") {
        return {
            ...base,
            borderColor: "gray.100",
        };
    }

    if (c === "red") {
        return {
            ...base,
            color: "black",
            bgColor: "red.50",
            borderColor: "red.200",
        };
    }

    return {
        ...base,
    };
};

type AccessibleColor = {
    bg?: string;
    color?: string;
    hoverBg?: string;
    activeBg?: string;
};

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
    yellow: {
        bg: "yellow.400",
        color: "black",
        hoverBg: "yellow.500",
        activeBg: "yellow.600",
    },
};

const variantStandard: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;
    const base = {
        _disabled: {
            bg: "gray.100",
            color: "black",
            "@media (hover: hover) and (pointer: fine) ": {
                _hover: {
                    bg: "gray.150",
                },
            },
        },
    };
    if (c === "white") {
        return {
            ...base,
            bg: "gray.100",
            color: "black",
            "@media (hover: hover) and (pointer: fine) ": {
                _hover: {
                    bg: "gray.150",
                    color: "black",
                },
            },
        };
    }

    if (c === "black") {
        return {
            ...base,
            bg: "gray.100",
            color: "black",
            "@media (hover: hover) and (pointer: fine) ": {
                _hover: {
                    bg: "gray.150",
                    color: "black",
                },
            },
            _focus: {
                bg: "gray.100",
                borderColor: "black",
            },
            _active: {
                bg: "gray.150",
            },
        };
    }

    const { bg = `${c}.500`, color = "white" } = accessibleColorMap[c] ?? {};

    return {
        bg,
        color,
    };
};

const variantSolid: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;
    const base = {
        _disabled: {
            bg: "gray.100",
            color: "black",
            "@media (hover: hover) and (pointer: fine) ": {
                _hover: {
                    bg: "gray.100",
                },
            },
        },
    };
    if (c === "white") {
        return {
            ...base,
            bg: "gray.100",
            color: "black",
            "@media (hover: hover) and (pointer: fine) ": {
                _hover: {
                    bg: "gray.150",
                    color: "black",
                },
            },
        };
    }

    if (c === "black") {
        return {
            ...base,
            bg: "black",
            color: "white",
            "@media (hover: hover) and (pointer: fine) ": {
                _hover: {
                    bg: "gray.800",
                    color: "white",
                },
            },
            _focus: {
                bg: "black",
            },
            _active: {
                bg: "black",
            },
        };
    }

    const { bg = `${c}.500`, color = "white" } = accessibleColorMap[c] ?? {};
    return {
        bg,
        color,
    };
};

const variantLink: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;
    return {
        padding: 0,
        px: 0,
        height: "auto",
        h: "auto",
        lineHeight: "normal",
        verticalAlign: "baseline",
        textDecoration: "underline",
        color: c === "black" || c === "white" ? c : `${c}.500`,
        _focusVisible: {
            boxShadow: "none",
            outlineColor: c === "black" ? whiten(c, 10) : blacken(c, 10),
            outline: "2px solid",
            outlineOffset: "2px",
            borderColor: c === "black" ? whiten(c, 10) : blacken(c, 10),
        },
        _hover: {
            textDecoration: "underline",
            _disabled: {
                textDecoration: "none",
            },
        },
        _active: {
            color: c === "black" || c === "white" ? c : `${c}.700`,
        },
    };
};

const variantUnstyled: SystemStyleObject = {
    bg: "none",
    color: "inherit",
    display: "inline",
    lineHeight: "inherit",
    m: 0,
    p: 0,
};

const variants = {
    tertiary: variantTertiary,
    outline: variantOutline,
    solid: variantSolid,
    standard: variantStandard,
    link: variantLink,
    unstyled: variantUnstyled,
    buttonFilter: variantButtonFilter,
};

const sizes: Record<string, SystemStyleObject> = {
    large: {
        h: [10, 12],
        minW: 12,
        py: 4,
    },
    normal: {
        h: [10, 12],
        minW: 10,
        py: 3,
    },
    small: {
        h: [10, 12],
        minW: 8,
    },
    tiny: {
        h: 6,
        minW: 6,
    },
    toggle: {
        h: 8,
        py: 2,
    },
    pill: {
        p: 2,
    },
    round: {
        width: 8,
        height: 8,
        p: 0,
        fontSize: ["mobileBodyTextTiny", "desktopBodyTextTiny"],
    },
};

const defaultProps = {
    variant: "solid",
    size: "small",
    colorScheme: "black",
};

export const buttonStyles = {
    baseStyle,
    variants,
    sizes,
    defaultProps,
};
