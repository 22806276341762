import type { ThemeConfig, ThemeDirection, ChakraTheme } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import colors from "./design-tokens/01.colors/colors";
import {
    fontFamilies,
    fontWeights,
    lineHeights,
    letterSpacings,
    fontSizes,
} from "./design-tokens/02.typography";
import space from "./design-tokens/03.space/space";
import sizes from "./design-tokens/04.sizes/sizes";
import radii from "./design-tokens/05.radii/radii";
import borders from "./design-tokens/06.borders/borders";
import shadows from "./design-tokens/08.shadows/shadows";
import breakpoints from "./design-tokens/09.breakpoints/breakpoints";
import zIndices from "./design-tokens/10.z-indices/z-indices";
import transition from "./design-tokens/12.transitions/transitions";
import { globalStyles } from "./global-styles";
import { buttonStyles } from "./components/forms/button/Button.styles";
import { headingStyles } from "./components/content/heading/Heading.styles";
import { textStyles } from "./components/content/text/Text.styles";
import { eccoLogoStyles } from "./components/media-and-icons/ecco-logo/ECCOLogo.styles";
import { linkStyles } from "./components/navigation/link/Link.styles";
import { surfaceStyles } from "./components/content/surface/Surface.styles";
import { inputStyles } from "./components/forms/input/Input.styles";
import { containerStyles } from "./components/layout/container/Container.styles";
import { formStyles } from "./components/forms/form-control/Form.styles";
import { formLabelStyles } from "./components/forms/form-control/FormLabel.styles";
import { formErrorStyles } from "./components/forms/form-control/FormError.styles";
import { dividerStyles } from "./components/data-display/divider/Divider.styles";
import { panelStyles } from "./components/content/panel/Panel.styles";
import { panelHeaderStyles } from "./components/content/panel/PanelHeader.styles";
import { panelSectionStyles } from "./components/content/panel/PanelSection.styles";
import { panelFooterStyles } from "./components/content/panel/PanelFooter.styles";
import { checkboxStyles } from "./components/forms/checkbox/Checkbox.styles";
import { sliderStyles } from "./components/forms/slider/Slider.styles";
import { badgeStyles } from "./components/data-display/badge/Badge.styles";
import { listStyles } from "./components/data-display/list/List.styles";
import { tagStyles } from "./components/data-display/tag/Tag.styles";
import { breadcrumbStyles } from "./components/navigation/breadcrumb/Breadcrumb.styles";
import { radioStyles } from "./components/forms/radio/Radio.styles";
import { rangeSliderStyles } from "./components/range/RangeSlider.styles";
import { selectStyles } from "./components/forms/select/Select.styles";
import { skeletonStyles } from "./components/feedback/skeleton/Skeleton.styles";
import { spinnerStyles } from "./components/feedback/spinner/Spinner.styles";
import { tableStyles } from "./components/data-display/table/Table.styles";
import { drawerStyles } from "./components/overlay/drawer/Drawer.styles";
import { menuStyles } from "./components/overlay/menu/Menu.styles";
import { modalStyles } from "./components/overlay/modal/Modal.styles";
import { tooltipStyles } from "./components/overlay/tooltip/Tooltip.styles";
import { iconButtonStyles } from "./components/forms/icon-button/IconButton.styles";
import { tabStyles } from "./components/disclosure/tabs/tabs.styles";
import { accordionStyles } from "./components/disclosure/accordion/Accordion.styles";
import { alertStyles } from "./components/feedback/alert/Alert.styles";
import { closeButtonStyles } from "./components/other/close-button/CloseButton.styles";
import { productRowStyles } from "./components/commerce/product-row/ProductRow.styles";
import { selectDropdownStyles } from "./components/overlay/select-dropdown/SelectDropdown.styles";
import { popoverStyles } from "./components/overlay/popover/Popover.styles";
import { eccoIconStyles } from "./components/media-and-icons/ecco-icon/ECCOIcon.styles";
import { spacerStyles } from "./components/layout/spacer/Spacer.styles";
import { progressStyles } from "./components/other/progress/Progress.styles";
import { textAreaStyles } from "./components/forms/textarea/Textarea.styles";

const direction: ThemeDirection = "ltr";

const config: ThemeConfig = {
    useSystemColorMode: false,
    initialColorMode: "light",
    cssVarPrefix: "ec",
};

const theme: ChakraTheme = {
    config,
    direction,
    breakpoints,
    colors,
    fonts: fontFamilies,
    fontSizes,
    fontWeights,
    letterSpacings,
    lineHeights,
    space,
    sizes,
    radii,
    borders,
    shadows,
    zIndices,
    transition,
    components: {
        Button: { ...buttonStyles },
        Heading: { ...headingStyles },
        Text: { ...textStyles },
        ECCOLogo: { ...eccoLogoStyles },
        Link: { ...linkStyles },
        Surface: { ...surfaceStyles },
        Input: { ...inputStyles },
        Container: { ...containerStyles },
        Form: { ...formStyles },
        FormLabel: { ...formLabelStyles },
        FormError: { ...formErrorStyles },
        Divider: { ...dividerStyles },
        Panel: { ...panelStyles },
        PanelHeader: { ...panelHeaderStyles },
        PanelSection: { ...panelSectionStyles },
        PanelFooter: { ...panelFooterStyles },
        Checkbox: { ...checkboxStyles },
        Slider: { ...sliderStyles },
        Badge: { ...badgeStyles },
        List: { ...listStyles },
        Tag: { ...tagStyles },
        Breadcrumb: { ...breadcrumbStyles },
        Radio: { ...radioStyles },
        RangeSlider: { ...rangeSliderStyles },
        Select: { ...selectStyles },
        Skeleton: { ...skeletonStyles },
        Spinner: { ...spinnerStyles },
        Table: { ...tableStyles },
        Drawer: { ...drawerStyles },
        Menu: { ...menuStyles },
        Modal: { ...modalStyles },
        Tooltip: { ...tooltipStyles },
        Tabs: { ...tabStyles },
        Accordion: { ...accordionStyles },
        Alert: { ...alertStyles },
        CloseButton: { ...closeButtonStyles },
        ProductRow: { ...productRowStyles },
        SelectDropdown: { ...selectDropdownStyles },
        Popover: { ...popoverStyles },
        Spacer: { ...spacerStyles },
        ECCOIcon: { ...eccoIconStyles },
        IconButton: { ...iconButtonStyles },
        Progress: { ...progressStyles },
        Textarea: { ...textAreaStyles },
    },
    styles: globalStyles,
};

const extendedChakraTheme: ChakraTheme = extendTheme(
    theme
) as ChakraTheme; /* fix for Prose typings not being updated with latest Chakra core release */

// Using `extendTheme` merges our theme values with the default Chakra theme - which is GREAT as we want the styles for their components,
// however ... we also get stuff we might not want, example is the default colors from Chakra which includes many more palettes than we need
delete extendedChakraTheme.colors.orange;
delete extendedChakraTheme.colors.teal;
delete extendedChakraTheme.colors.blue;
delete extendedChakraTheme.colors.cyan;
delete extendedChakraTheme.colors.purple;
delete extendedChakraTheme.colors.pink;
delete extendedChakraTheme.colors.linkedin;
delete extendedChakraTheme.colors.facebook;
delete extendedChakraTheme.colors.messenger;
delete extendedChakraTheme.colors.whatsapp;
delete extendedChakraTheme.colors.twitter;
delete extendedChakraTheme.colors.telegram;

delete extendedChakraTheme.components.Input.variants?.filled;
delete extendedChakraTheme.components.Input.variants?.flushed;

delete extendedChakraTheme.components.Divider.variants?.dashed;

delete extendedChakraTheme.components.Alert.variants?.["left-accent"];
delete extendedChakraTheme.components.Alert.variants?.["top-accent"];

["4xl", "3xl", "2xl", "xl", "lg", "md", "sm", "xs"].forEach(
    (size) => delete extendedChakraTheme.components.Heading.sizes![size]
);

export type { ChakraTheme as ECCOTheme };

export { extendedChakraTheme as theme };
