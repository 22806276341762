import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import type { PartsStyleObject, SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleObject<typeof parts> = {
    field: {
        width: "100%",
        minWidth: 0,
        outline: 0,
        position: "relative",
        appearance: "none",
        transitionProperty: "none",
        transitionDuration: "0s",
    },
};

const size: Record<string, SystemStyleObject> = {
    normal: {
        fontSize: "100%",
        fontWeight: "semibold",
        px: 6,
        h: 12,
        borderRadius: "none",
    },

    small: {
        // This is a workaround for having a input fontSize smaller than 16px
        // on ios mobile devices the input will zoom in when focused. To avoid
        // this we scale the input down and move it to the left to replicate the
        // fontSize of 14px.
        // For more info see: https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
        transform: ["scale(0.875)", "scale(1)"],
        transformOrigin: "left center",
        marginRight: "-14.28%",
        fontWeight: "semibold",
        fontSize: "desktopBodyTextSmall",
        width: ["114.28%", "100%"],
        px: 6,
        h: 12,
        borderRadius: "none",
    },

    tiny: {
        // This is a workaround for having a input fontSize smaller than 16px
        // on ios mobile devices the input will zoom in when focused. To avoid
        // this we scale the input down and move it to the left to replicate the
        // fontSize of 12px.
        // For more info see: https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
        transform: "scale(0.75)",
        transformOrigin: "left center",
        marginRight: "-33.33%",
        fontWeight: "semibold",
        px: 2,
        h: 8,
        borderRadius: "none",
    },
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
    normal: {
        field: size.normal,
        addon: size.normal,
    },
    small: {
        field: size.small,
        addon: size.small,
    },
    tiny: {
        field: size.tiny,
        addon: size.tiny,
    },
};

const variantOutline: PartsStyleObject<typeof parts> = {
    field: {
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "gray.400",
        bg: "white",
        borderRadius: "full",
        _hover: {
            borderColor: "black",
        },
        _readOnly: {
            boxShadow: "none !important",
            userSelect: "all",
            transitionProperty: "none",
            _focus: {
                borderColor: "gray.400",
                outlineWidth: "0",
                outlineStyle: "none",
                outlineColor: "inherit",
                outlineOffset: "0",
            },
        },
        _disabled: {
            opacity: 0.4,
            cursor: "not-allowed",
        },
        _invalid: {
            borderColor: "red.600",
            bg: "red.50",
            boxShadow: "none",
            _focus: {
                bg: "white",
                outlineWidth: "0",
            },
        },
        _focus: {
            zIndex: 1,
            transitionProperty: "none",
            transitionDuration: "0s",
            outlineWidth: ".5px",
            outlineStyle: "solid",
            outlineColor: "black",
            outlineOffset: "0",
            boxShadow: "none",
        },
    },
    addon: {
        border: "1px solid",
        borderColor: "gray.400",
        bg: "gray.50",
    },
};

const variantUnstyled: PartsStyleObject<typeof parts> = {
    field: {
        bg: "transparent",
        px: 0,
        height: "auto",
    },
    addon: {
        bg: "transparent",
        px: 0,
        height: "auto",
    },
};

const variants = {
    outline: variantOutline,
    unstyled: variantUnstyled,
};

const defaultProps = {
    size: "small",
};

export const inputStyles = {
    parts: parts.keys,
    baseStyle,
    sizes,
    variants,
    defaultProps,
};
